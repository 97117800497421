import React from "react";
import { toast } from "react-toastify";
import ToastCard from "../components/toast-card/ToastCard";

export const toaster = {
    error: ({ title, tKey = "66", ...cardProps }, toastProps) => {
        toast.dismiss(); // Yeni toast geldiğinde öncekini kapat
        return toast.error(
            <ToastCard tKey={tKey} type="error" title={title} {...cardProps} />,
            {
                autoClose: 1000, // Süreyi 1 saniye yap
                ...toastProps,
            }
        );
    },
    warning: ({ title, tKey = "67", ...cardProps }, toastProps) => {
        toast.dismiss();
        return toast.warning(
            <ToastCard tKey={tKey} type="warning" title={title} {...cardProps} />,
            {
                autoClose: 1000,
                ...toastProps,
            }
        );
    },
    info: ({ title, tKey = "68", ...cardProps }, toastProps) => {
        toast.dismiss();
        return toast.info(
            <ToastCard tKey={tKey} type="info" title={title} {...cardProps} />,
            {
                autoClose: 1000,
                ...toastProps,
            }
        );
    },
    success: ({ title, tKey = "69", ...cardProps }, toastProps) => {
        toast.dismiss();
        return toast.success(
            <ToastCard tKey={tKey} type="success" title={title} {...cardProps} />,
            {
                autoClose: 1000,
                ...toastProps,
            }
        );
    },
};
