import { createSlice } from "@reduxjs/toolkit";
import {deepEqual} from "../helpers/deepEqual";

const sliceName = "basket";

const initialState = {
  error: null,
  loading: false,
  basket: [],
  confirmBasket: [],
};


export const basketSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    addToBasket: (state, { payload }) => {
      const {
        data,
        basketNote,
        selectedRemovedMaterials,
        preferences,
        totalPrice,
      } = payload;

      const existingProduct = state.basket.find((item) => {

        return (

            item.id === data.id &&
            item.noteDetail === basketNote &&
            item.issuedContent === selectedRemovedMaterials?.toString() &&
            deepEqual(item.preferences, preferences) // Derin karşılaştırma burada
        );
      });

      if (existingProduct) {
        state.basket = state.basket.map((item) => {
          if (
              item.id === data.id &&
              item.noteDetail === basketNote &&
              item.issuedContent === selectedRemovedMaterials?.toString() &&
              deepEqual(item.preferences, preferences) // Derin karşılaştırma burada
          ) {
            return { ...item, count: item.count + 1, totalPrice };
          } else {
            return item;
          }
        });
      } else {
        state.basket.push({
          ...data,
          count: 1,
          noteDetail: basketNote,
          issuedContent: selectedRemovedMaterials?.toString(),
          preferences,
          totalPrice,
        });
      }
    },
    setBasket: (state, { payload }) => {
      state.basket = payload || [];
    },
    increase: (state, { payload }) => {
      const product = payload;
      state.basket = state.basket.map((item) => {
        if (item.id === product.id &&
            item.noteDetail === product.noteDetail &&
            item.issuedContent === product.issuedContent &&
            deepEqual(item.preferences, product.preferences)  ) {
          return { ...item, count: item.count + 1 };
        } else {
          return item;
        }
      });
    },
    decrease: (state, { payload }) => {
      const product = payload;
      state.basket = state.basket
          .map((item) => {
            if (item.id === product.id &&
                item.noteDetail === product.noteDetail &&
                item.issuedContent === product.issuedContent &&
                deepEqual(item.preferences, product.preferences)) {
              if (item.count > 1) {
                return { ...item, count: item.count - 1 };
              } else {
                return null;
              }
            } else {
              return item;
            }
          })
          .filter((item) => item !== null);
    },
    setConfirmBasket: (state, { payload }) => {
      state.confirmBasket = payload;
    },
  },
});

export const basketActions = {
  ...basketSlice.actions,
};
